import { Link, Outlet } from "react-router-dom";

const BookLayout = () => {
    return ( <div className="booklayout">
        <Link to="/books/1">Book 1</Link><br /><br />
        <Link to="/books/2">Book 2</Link><br /><br />
        <Link to="/books/3">Book 3</Link><br /><br />
        <Link to="/books/new">New Book</Link><br /><br />
        <Outlet />
    </div> );
}
 
export default BookLayout;
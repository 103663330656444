import HomeClientComments from "./Home/ClientComments";
import HomeFaq from "./Home/Faq";
import HomeHeader from "./Home/Header";
import HomePlans from "./Home/Plans";
import HomePortfolio from "./Home/Portfolio";
import HomePromote from "./Home/Promote";
import HomeQa from "./Home/Qa";
import HomeServices from "./Home/Services";

const Home = ({products, plans}) => {

    return ( 
        <div className="home w-full">
            <HomeHeader />

            <HomeFaq />

            <HomePromote />

            <HomeServices products={products} />

            <HomePortfolio products={products} />

            <HomePlans plans={plans} />

            <HomeQa />

            <HomeClientComments />
        </div>
     );
}

export default Home;

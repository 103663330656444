import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { Link } from "react-router-dom";
import NavbarMenuPortfolioItemChild from "./NavbarMenuPortfolioItemChild copy";

const NavbarMenuPortfolioItem = ({item}) => {

    const megaMenuToggler = () => {
        document.querySelector(`.megaMenu-${item.key}`).classList.toggle('show')
        document.querySelector(`.chevronLeft-${item.key}`).classList.toggle('chevronActive')
    }

    const webPortfolio = item.childsWeb
    const gfxPortfolio = item.childsGraphic

    const [activeItem, setActiveItem] = useState(webPortfolio)

    const webItemToggler = (e) => {
        setActiveItem(webPortfolio)
    }
    const gfxItemToggler = (e) => {
        setActiveItem(gfxPortfolio)
    }

    return ( 
        <div className="navbarItem">
            <span onClick={megaMenuToggler} className="navbarItemTitle flex flex-row items-center font-bold">
                <button className="py-2 pr-4 transition-all duration-300">
                    {item.title}
                </button>
                <span className={`chevronLeft chevronLeft-${item.key} text-3xl transition-all duration-300`}>
                    <BiChevronLeft />
                </span>
            </span>
                <div className={`megaMenu megaMenu-${item.key} w-full px-4 md:p-4 flex-col justify-between items-center shadow-xl rounded-br-nonerounded-bl-none md:rounded-br-2xl  md:rounded-bl-2xl `}>
                    <div className="megaMenuPortfolioButtons my-4 md:my-8 p-1 text-lg md:text-2xl font-bold shadow-md border-2 border-opacity-10 rounded-lg border-primary">
                        <button onClick={webItemToggler} className={`ml-2 px-8 py-2 rounded-md ${activeItem===webPortfolio ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                            وبسایت
                        </button>
                        <button onClick={gfxItemToggler} className={`mr-2 px-8 py-2 rounded-md ${activeItem===gfxPortfolio ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                            گرافیک
                        </button>
                    </div>
                    <div className="megaMenuPortfolioItemsBox w-full grid grid-cols-1 md:grid-cols-5 gap-4 lg:gap-8">
                        {activeItem.slice(0, 5).map(e=>{
                            return <NavbarMenuPortfolioItemChild key={e.key} item={e} />
                        })}
                    </div>
                    <div className="megaMenuPortfolioLink my-8">
                        <Link to={activeItem===webPortfolio ? '/webportfolio':'graphicportfolio'} className={`px-6 md:px-12 py-2 md:py-3 text-lg md:text-xl shadow-md border-2 rounded-lg border-primary hover:bg-primary hover:text-white transition-all`}>
                            سایر نمونه کارها
                        </Link>
                    </div>
                </div>
        </div>
     );
}
 
export default NavbarMenuPortfolioItem;
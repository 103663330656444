import HomeFaqItem from "./FaqItem";

const HomeFaq = () => {

    const item = [
        {
            "key": "1",
            "title": "مشاوره رایگان",
            "content": `
            تیم پشتیبانی و تیم فنی
            `,
            "icon": `./img/faq1.svg`,
        },
        {
            "key": "2",
            "title": "پشتیبانی تمام وقت",
            "content": `
            همه خدمات ما دارای گارانتی
            `,
            "icon": "./img/faq2.svg",
        },
        {
            "key": "3",
            "title": "بیش از ۱۰ سال سابقه",
            "content": `
            
            `,
            "icon": "./img/faq3.svg",
        },
        {
            "key": "4",
            "title": "رضایت مشتریان",
            "content": `
            
            `,
            "icon": "./img/faq4.svg",
        },
    ]

    return ( 
        <section id="faq" className="homeFaq container mx-auto w-full flex flex-col">
            <h2 className="px-4 md:px-0 pt-32 text-5xl font-bold text-primary">
                چرا ریور استودیو؟
            </h2>
            <div className="w-full mt-16 px-4 md:px-0 flex flex-col md:flex-row justify-around items-start">
                {item.map(e=>{
                    return <HomeFaqItem key={e.key} title={e.title} content={e.content} icon={e.icon} />
                })}
            </div>
        </section>
     );
}
 
export default HomeFaq;
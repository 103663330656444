import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import HomeClientCommentsItem from './ClientCommentsItem';


const HomeClientComments = () => {

    SwiperCore.use([Autoplay]);

    const clientComments = [
        {
            id:1,
            name: 'Aref Fallah',
            username: 'iamrf',
            email: 'rf.fa17@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/avatar2.png',
            comment: 'اگر خواهان دیده شدن توسط مخاطبان هدف خود هستید، حتما باید پا به عرصه ی دنیای آنلاین بگذارید.',
        },
        {
            id:2,
            name: 'Ghasem /hf',
            username: 'ghf',
            email: 'ghasem@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/avatar1.png',
            comment: 'اگر خواهان دیده شدن توسط مخاطبان هدف خود هستیای آنلاین بگذارید.',
        },
        {
            id:3,
            name: 'Hasan Abbasi',
            username: 'iamhasan',
            email: 'hasan@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/faq2.svg',
            comment: 'اگر خواهان دیده ش، حتما باید پا به عرصه ی دنیای آنلاین بگذارید.',
        },
        {
            id:4,
            name: 'Mobin Taslimi',
            username: 'mobTin',
            email: 'godofamel@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/faq3.svg',
            comment: 'اگر خواهان دیده شدن توسط مخاطبان هدف خود هستید، حتما باید پا بهنلاین بگذارید.',
        },
        {
            id:5,
            name: 'DR Abdi',
            username: 'iamthedoctor',
            email: 'ghasemabdi@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/faq4.svg',
            comment: 'اگر خواهان دیده شدن توسط مخاطبان هدف خود هستید، حتما باید پا به عرصه ی بگذارید.',
        },
        {
            id:6,
            name: 'MahyarGH',
            username: 'mahYr',
            email: 'MahyarEng@gmail.com',
            network: 'Instagram',
            date: '27 Jan, 2022',
            icon: './img/faq2.svg',
            comment: 'اگر خواهان دیده شدن توسط مخاطبان هدف خود هستید، حتما باین بگذارید.',
        }
    ]

    return ( 
        <section id="clientComments" className="homeClientComments w-full container mx-auto mt-20 md:mt-24 px-4 md:px-0 flex flex-col justify-start items-center text-primary">
            <h2 className="text-4xl md:text-5xl font-bold">
                نظر مشتریان
            </h2>

            <Swiper
                className="clientCommentsSwiperCarousel w-full mt-4 md:mt-12 -mb-12 py-4 h-400 md:h-300 overflow-hidden"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                loop='true'
                direction='vertical'
                speed={3000}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                }}
                breakpoints={{
                    576: {
                    // width: 576,
                    slidesPerView: 1,
                    },
                    768: {
                    // width: 768,
                    slidesPerView: 1,
                    },
                    1024: {
                    // width: 768,
                    slidesPerView: 1,
                    },
                    2000: {
                        slidesPerView: 2,
                    }
                }}
                // onSlideChange={() => console.log('slide change')}
            >

                {clientComments.map(e=>{
                    return <SwiperSlide key={e.id}><HomeClientCommentsItem item={e} /></SwiperSlide>
                })}

            </Swiper>
        </section>
     );
}
 
export default HomeClientComments;
import { Link } from "react-router-dom";

const NavbarMenuPortfolioItemChild = ({item}) => {
    return ( 
            <Link to={item.link ? item.link:'/portfolio'} className="navbarMenuPortfolioItemChild overflow-hidden w-full flex flex-row justify-center items-center rounded-xl transition-all duration-300">
                <div className="navPortfolioItem relative w-full">
                    <img className="w-full h-300 md:h-200 lg:h-250 object-center object-cover transition-all duration-500" src={item.icon1} alt={item.title} />
                    <div className="navPortfolioItemContent absolute top-0 right-0 bottom-0 left-0 w-full text-center flex flex-col justify-center items-center bg-primary text-white transition-all duration-500">
                        <h2 className="text-lg md:text-xl transition-all duration-300">
                            {item.title}
                        </h2>
                        {item.desc && (
                            <h4 className="mt-2 text-sm md:text-lg opacity-50 transition-all duration-300">
                                {item.desc}
                            </h4>
                        )}
                    </div>
                </div>
        </Link>
     );
}

export default NavbarMenuPortfolioItemChild;
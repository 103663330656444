import { Link } from "react-router-dom"

const HomeServicesCarouselItem = ({item}) => {
    return (
        <Link to={item.link ? item.link : '/'} className="homeServiceCarouselItem z-0 h-400 relative py-12 px-2 md:px-4 mt-8 hover:mt-4 mx-2 flex flex-col justify-start items-center hover:bg-secondary text-primary hover:text-white bg-primary bg-opacity-5 rounded-xl transition-all duration-300">
            <div className="homeServiceCarouselItemIcon absolute transition-all duration-300">
                <svg className="w-20 h-20" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d={item.icon1} fill="#0E5395"/>
                    {item.icon2? (
                    <path d={item.icon2} fill="#0E5395"/>
                    ):
                    ""}
                    {item.icon3? (
                    <path d={item.icon3} fill="#0E5395"/>
                    ):
                    ""}
                </svg>
            </div>
            <svg className="animItemHoverCircleTL -z-10 opacity-20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="white"/>
            </svg>
            <svg className="animItemHoverCircleTL2 -z-10 opacity-20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="white"/>
            </svg>
            <h4 className="z-0 text-2xl font-bold leading-loose transition-all duration-500">{item.title}</h4>
            <div className="z-0 mt-4 text-xl leading-loose overflow-hidden">{item.content}</div>
        </Link>
     );
}

export default HomeServicesCarouselItem;
import { Link } from "react-router-dom";

const HomePortfolioItem = ({item}) => {
    return ( 
        <Link to={item.link ? item.link: '/'}>
            <div className="homePortfolioItem relative w-full rounded-xl overflow-hidden">
                <img className="w-full h-300 md:h-200 md:h-300 lg:h-400 xl:h-500 object-center object-cover transition-all duration-300" src={item.icon1} alt={item.title} />
                <div className="homePortfolioItemHover absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col justify-center items-center bg-primary">
                    <h5 className="w-full text-center text-3xl text-white">
                        {item.title}
                    </h5>
                    <h6 className="w-full mt-8 text-center text-xl text-white">
                        {item.desc}
                    </h6>
                </div>
            </div>
        </Link>
     );
}

export default HomePortfolioItem;
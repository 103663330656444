const HomePlansItemLine = ({item}) => {
    return ( 
        <div className="py-2 md:mx-8 flex flex-row justify-start items-center border-b-2 border-primary border-opacity-10 text-lg md:text-xl leading-loose">
            <span>
                <svg className="w-4 md:w-6 h-4 md:h-6" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.4818 14.8482L32.0676 13.386C31.0389 12.3221 30.5574 10.8423 30.7654 9.37687L31.0501 7.36272C31.1749 6.47963 30.5766 5.65733 29.6983 5.50215L27.6954 5.15019C26.2379 4.89422 24.9789 3.98074 24.2846 2.6721L23.3311 0.875523C22.9136 0.0916204 21.9457 -0.22354 21.1458 0.168411L19.3188 1.0611C17.9894 1.71062 16.4328 1.71062 15.1033 1.0611L13.2748 0.168411C12.4749 -0.22354 11.507 0.0916205 11.0894 0.878723L10.1359 2.6753C9.44163 3.98394 8.18419 4.89742 6.72517 5.15339L4.72222 5.50535C3.84392 5.65893 3.2456 6.48123 3.37038 7.36432L3.65515 9.37846C3.86312 10.8439 3.38158 12.3237 2.35291 13.3876L0.938687 14.8498C0.317964 15.4913 0.317964 16.5072 0.938687 17.1487L2.35291 18.6109C3.38158 19.6748 3.86312 21.1546 3.65515 22.62L3.37038 24.6342C3.2456 25.5173 3.84392 26.3396 4.72222 26.4947L6.72517 26.8467C8.18259 27.1027 9.44163 28.0161 10.1359 29.3248L11.0894 31.1214C11.507 31.9085 12.4749 32.2236 13.2764 31.8317L15.1033 30.939C16.4328 30.2895 17.9894 30.2895 19.3188 30.939L21.1458 31.8317C21.9473 32.2236 22.9136 31.9085 23.3327 31.1214L24.2862 29.3248C24.9805 28.0177 26.2379 27.1027 27.697 26.8467L29.6999 26.4947C30.5782 26.3412 31.1765 25.5173 31.0517 24.6342L30.767 22.62C30.559 21.1546 31.0405 19.6748 32.0692 18.6109L33.4834 17.1487C34.101 16.5072 34.101 15.4897 33.4818 14.8482ZM14.4794 21.9289L10.8111 18.2606C10.1871 17.6366 10.1871 16.6224 10.8111 15.9984C11.435 15.3745 12.4493 15.3745 13.0732 15.9984L15.6105 18.5357L22.9471 11.199C23.5711 10.5751 24.5853 10.5751 25.2093 11.199C25.8332 11.823 25.8332 12.8372 25.2093 13.4612L16.7415 21.9289C16.1176 22.5528 15.1033 22.5528 14.4794 21.9289Z" fill="#0E5395"/>
                </svg>
            </span>
            <span className="mr-4">{item}</span>
        </div>
     );
}
 
export default HomePlansItemLine;
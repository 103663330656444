import HomeHeaderCarousel from "./HeaderCarousel";

const HomeHeader = () => {

    return ( 
        <section id="header" className="homeHeader relative h-screen pt-20 md:pt-40 flex flex-row justify-center">
            {/* Hero Animation */}
            <div className="heroAnimateShapes absolute top-0 right-0 bottom-0 left-0">
                <svg className="animRect -z-10 opacity-50 w-24 md:w-40 h-24 md:h-40" viewBox="0 0 167 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.15" x="7.52051" y="7.5" width="151.77" height="151" rx="31.5" stroke="#0E5395" strokeWidth="15"/>
                </svg>
                <svg className="animCircle -z-10 opacity-50 w-24 md:w-40 h-24 md:h-40" viewBox="0 0 157 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M78.5902 141C113.545 141 141.882 112.794 141.882 78C141.882 43.2061 113.545 15 78.5902 15C43.6349 15 15.2981 43.2061 15.2981 78C15.2981 112.794 43.6349 141 78.5902 141ZM78.5902 156C121.868 156 156.952 121.078 156.952 78C156.952 34.9218 121.868 0 78.5902 0C35.3122 0 0.228516 34.9218 0.228516 78C0.228516 121.078 35.3122 156 78.5902 156Z" fill="#00FF19"/>
                </svg>
                <svg className="animPlus -z-10 opacity-50 w-24 md:w-40 h-24 md:h-40" viewBox="0 0 171 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M77.2298 0V76.05H0.827148V92.95H77.2298V169H94.2081V92.95H170.611V76.05H94.2081V0H77.2298Z" fill="#A02724"/>
                </svg>
                <svg className="animTriangle -z-10 opacity-50 w-24 md:w-40 h-24 md:h-40" viewBox="0 0 182 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.35" d="M89.63 9.18513C90.208 8.18859 91.647 8.18861 92.2251 9.18512L174.045 150.247C174.625 151.247 173.904 152.5 172.747 152.5H9.10755C7.95151 152.5 7.22999 151.247 7.81002 150.247L89.63 9.18513Z" stroke="#FFB573" strokeWidth="15"/>
                </svg>
                <svg className="animPolygon -z-10 opacity-50 w-24 md:w-40 h-24 md:h-40" viewBox="0 0 175 194" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" d="M79.482 10.375C84.5726 7.44953 90.8343 7.44953 95.9248 10.375L158.929 46.5825C164.05 49.5256 167.208 54.9818 167.208 60.8884V133.112C167.208 139.018 164.05 144.474 158.929 147.417L95.9248 183.625C90.8343 186.55 84.5726 186.55 79.482 183.625L16.4779 147.417C11.3567 144.474 8.19933 139.018 8.19933 133.112V60.8884C8.19933 54.9818 11.3567 49.5256 16.4779 46.5825L79.482 10.375Z" stroke="#9747FF" strokeWidth="15"/>
                </svg>

            </div>

            {/* Hero Content */}
            <HomeHeaderCarousel />
        </section>
     );
}

export default HomeHeader;
import { useState } from "react";
import HomeServicesCarousel from "./ServicesCarousel";

const HomeServices = ({products}) => {

    const [activeItem, setActiveItem] = useState(products[0].find(e=>e.key==='web-design'))

    const webItemToggler = (e) => {
        setActiveItem(products[0].find(e=>e.key==='web-design'))
    }
    const gfxItemToggler = (e) => {
        setActiveItem(products[0].find(e=>e.key==='graphic-design'))
    }

    return ( 
        <section id="services" className="homeServices w-full container mx-auto mt-32 md:mt-40 px-4 md:px-0 flex flex-col justify-start items-center text-primary">
            <h2 className="text-5xl font-bold">
                خدمات ما
            </h2>
            <h4 className="w-full md:w-1/2 mt-8 text-gray-600 text-xl text-center leading-loose">
                خدمات ما به دو بخش اصلی طراحی سایت و طراحی گرافیک تقسیم میشود، در باکس زیر با جزییات بیشتری از خدمات ما آشنا شوید.
            </h4>
            <div className="mt-8 p-1 text-2xl font-bold shadow-md border-2 border-opacity-10 rounded-lg border-primary">
                <button onClick={webItemToggler} className={`ml-2 px-8 py-2 rounded-md ${activeItem===products[0].find(e=>e.key==='web-design') ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                    وبسایت
                </button>
                <button onClick={gfxItemToggler} className={`mr-2 px-8 py-2 rounded-md ${activeItem===products[0].find(e=>e.key==='graphic-design') ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                    گرافیک
                </button>
            </div>
            <div className="w-full md:pt-8">
                <HomeServicesCarousel item={activeItem} />
            </div>
        </section>
     );
}

export default HomeServices;
const HomeQa = () => {
    return ( 
        <section id="qa" className="homeQa w-full container mx-auto mt-32 md:mt-40 px-4 md:px-0 flex flex-col justify-start items-center text-primary">
            <h2 className="text-5xl font-bold">سوالات متداول</h2>
            <div className="w-full mt-8 md:mt-12 flex flex-col">
                <div className="homeQaItem w-full">
                    <input type="radio" name="homeQa" id="qaFirst" />
                    <label className="flex flex-row justify-between items-center cursor-pointer text-lg md:text-2xl transition-all" htmlFor="qaFirst">
                        <span>
                        چگونه می‌توانم سفارش طراحی را  ثبت کنم؟
                        </span>
                        <span>
                        <svg className="w-6 md:w-8 h-6 md:h-8" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="#0E5395"/>
                            <path d="M28.5011 8C23.1212 8 18.0012 12.3735 18.0012 18.4C17.9967 18.718 18.056 19.0338 18.1757 19.3289C18.2955 19.624 18.4732 19.8926 18.6987 20.1191C18.9241 20.3456 19.1928 20.5255 19.489 20.6482C19.7852 20.771 20.1032 20.8342 20.4243 20.8342C20.7454 20.8342 21.0633 20.771 21.3595 20.6482C21.6558 20.5255 21.9244 20.3456 22.1499 20.1191C22.3753 19.8926 22.5531 19.624 22.6728 19.3289C22.7925 19.0338 22.8519 18.718 22.8473 18.4C22.8473 15.1497 25.8527 12.8 28.5011 12.8C31.1495 12.8 34.1549 15.1497 34.1549 18.4C34.1549 19.7947 33.784 20.6488 33.1074 21.5656C32.4308 22.4824 31.3764 23.3937 30.1575 24.425C27.7196 26.4875 24.4627 29.44 24.4627 34.4C24.4581 34.718 24.5175 35.0338 24.6372 35.3289C24.7569 35.624 24.9347 35.8926 25.1601 36.1191C25.3856 36.3456 25.6542 36.5255 25.9505 36.6482C26.2467 36.771 26.5646 36.8342 26.8857 36.8342C27.2068 36.8342 27.5248 36.771 27.821 36.6482C28.1172 36.5255 28.3859 36.3456 28.6113 36.1191C28.8368 35.8926 29.0145 35.624 29.1343 35.3289C29.254 35.0338 29.3133 34.718 29.3088 34.4C29.3088 31.36 30.8979 30.1125 33.3062 28.075C34.5103 27.0563 35.8789 25.9426 37.0196 24.3969C38.1603 22.8512 39.001 20.8053 39.001 18.4C39.001 12.3735 33.881 8 28.5011 8ZM26.8857 40C25.8147 40 24.7875 40.4214 24.0301 41.1716C23.2728 41.9217 22.8473 42.9391 22.8473 44C22.8473 45.0609 23.2728 46.0783 24.0301 46.8284C24.7875 47.5786 25.8147 48 26.8857 48C27.9568 48 28.984 47.5786 29.7413 46.8284C30.4987 46.0783 30.9241 45.0609 30.9241 44C30.9241 42.9391 30.4987 41.9217 29.7413 41.1716C28.984 40.4214 27.9568 40 26.8857 40Z" fill="white"/>
                        </svg>
                        </span>
                    </label>
                    <div className="homeQaItemActive my-6 pr-4 text-xl">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                    </div>
                </div>
                <div className="homeQaItem w-full">
                    <input type="radio" name="homeQa" id="qaSecond" />
                    <label className="flex flex-row justify-between items-center cursor-pointer text-lg md:text-2xl transition-all" htmlFor="qaSecond">
                        <span>
                        فرایند قیمت گذاری در استودیو ریور به چه صورت است؟
                        </span>
                        <span>
                        <svg className="w-6 md:w-8 h-6 md:h-8" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="#0E5395"/>
                            <path d="M28.5011 8C23.1212 8 18.0012 12.3735 18.0012 18.4C17.9967 18.718 18.056 19.0338 18.1757 19.3289C18.2955 19.624 18.4732 19.8926 18.6987 20.1191C18.9241 20.3456 19.1928 20.5255 19.489 20.6482C19.7852 20.771 20.1032 20.8342 20.4243 20.8342C20.7454 20.8342 21.0633 20.771 21.3595 20.6482C21.6558 20.5255 21.9244 20.3456 22.1499 20.1191C22.3753 19.8926 22.5531 19.624 22.6728 19.3289C22.7925 19.0338 22.8519 18.718 22.8473 18.4C22.8473 15.1497 25.8527 12.8 28.5011 12.8C31.1495 12.8 34.1549 15.1497 34.1549 18.4C34.1549 19.7947 33.784 20.6488 33.1074 21.5656C32.4308 22.4824 31.3764 23.3937 30.1575 24.425C27.7196 26.4875 24.4627 29.44 24.4627 34.4C24.4581 34.718 24.5175 35.0338 24.6372 35.3289C24.7569 35.624 24.9347 35.8926 25.1601 36.1191C25.3856 36.3456 25.6542 36.5255 25.9505 36.6482C26.2467 36.771 26.5646 36.8342 26.8857 36.8342C27.2068 36.8342 27.5248 36.771 27.821 36.6482C28.1172 36.5255 28.3859 36.3456 28.6113 36.1191C28.8368 35.8926 29.0145 35.624 29.1343 35.3289C29.254 35.0338 29.3133 34.718 29.3088 34.4C29.3088 31.36 30.8979 30.1125 33.3062 28.075C34.5103 27.0563 35.8789 25.9426 37.0196 24.3969C38.1603 22.8512 39.001 20.8053 39.001 18.4C39.001 12.3735 33.881 8 28.5011 8ZM26.8857 40C25.8147 40 24.7875 40.4214 24.0301 41.1716C23.2728 41.9217 22.8473 42.9391 22.8473 44C22.8473 45.0609 23.2728 46.0783 24.0301 46.8284C24.7875 47.5786 25.8147 48 26.8857 48C27.9568 48 28.984 47.5786 29.7413 46.8284C30.4987 46.0783 30.9241 45.0609 30.9241 44C30.9241 42.9391 30.4987 41.9217 29.7413 41.1716C28.984 40.4214 27.9568 40 26.8857 40Z" fill="white"/>
                        </svg>
                        </span>
                    </label>
                    <div className="homeQaItemActive my-6 pr-4 text-xl">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                    </div>
                </div>
                <div className="homeQaItem w-full">
                    <input type="radio" name="homeQa" id="qaThird" />
                    <label className="flex flex-row justify-between items-center cursor-pointer text-lg md:text-2xl transition-all" htmlFor="qaThird">
                        <span>
                        چگونه می‌توانم سفارش را ثبت کنم؟
                        </span>
                        <span>
                        <svg className="w-6 md:w-8 h-6 md:h-8" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="#0E5395"/>
                            <path d="M28.5011 8C23.1212 8 18.0012 12.3735 18.0012 18.4C17.9967 18.718 18.056 19.0338 18.1757 19.3289C18.2955 19.624 18.4732 19.8926 18.6987 20.1191C18.9241 20.3456 19.1928 20.5255 19.489 20.6482C19.7852 20.771 20.1032 20.8342 20.4243 20.8342C20.7454 20.8342 21.0633 20.771 21.3595 20.6482C21.6558 20.5255 21.9244 20.3456 22.1499 20.1191C22.3753 19.8926 22.5531 19.624 22.6728 19.3289C22.7925 19.0338 22.8519 18.718 22.8473 18.4C22.8473 15.1497 25.8527 12.8 28.5011 12.8C31.1495 12.8 34.1549 15.1497 34.1549 18.4C34.1549 19.7947 33.784 20.6488 33.1074 21.5656C32.4308 22.4824 31.3764 23.3937 30.1575 24.425C27.7196 26.4875 24.4627 29.44 24.4627 34.4C24.4581 34.718 24.5175 35.0338 24.6372 35.3289C24.7569 35.624 24.9347 35.8926 25.1601 36.1191C25.3856 36.3456 25.6542 36.5255 25.9505 36.6482C26.2467 36.771 26.5646 36.8342 26.8857 36.8342C27.2068 36.8342 27.5248 36.771 27.821 36.6482C28.1172 36.5255 28.3859 36.3456 28.6113 36.1191C28.8368 35.8926 29.0145 35.624 29.1343 35.3289C29.254 35.0338 29.3133 34.718 29.3088 34.4C29.3088 31.36 30.8979 30.1125 33.3062 28.075C34.5103 27.0563 35.8789 25.9426 37.0196 24.3969C38.1603 22.8512 39.001 20.8053 39.001 18.4C39.001 12.3735 33.881 8 28.5011 8ZM26.8857 40C25.8147 40 24.7875 40.4214 24.0301 41.1716C23.2728 41.9217 22.8473 42.9391 22.8473 44C22.8473 45.0609 23.2728 46.0783 24.0301 46.8284C24.7875 47.5786 25.8147 48 26.8857 48C27.9568 48 28.984 47.5786 29.7413 46.8284C30.4987 46.0783 30.9241 45.0609 30.9241 44C30.9241 42.9391 30.4987 41.9217 29.7413 41.1716C28.984 40.4214 27.9568 40 26.8857 40Z" fill="white"/>
                        </svg>
                        </span>
                    </label>
                    <div className="homeQaItemActive my-6 pr-4 text-xl">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                    </div>
                </div>
                <div className="homeQaItem w-full">
                    <input type="radio" name="homeQa" id="qaFourth" />
                    <label className="flex flex-row justify-between items-center cursor-pointer text-lg md:text-2xl transition-all" htmlFor="qaFourth">
                        <span>
                        چگونه می‌توانم سفارش را ثبت کنم؟
                        </span>
                        <span>
                        <svg className="w-6 md:w-8 h-6 md:h-8" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="#0E5395"/>
                            <path d="M28.5011 8C23.1212 8 18.0012 12.3735 18.0012 18.4C17.9967 18.718 18.056 19.0338 18.1757 19.3289C18.2955 19.624 18.4732 19.8926 18.6987 20.1191C18.9241 20.3456 19.1928 20.5255 19.489 20.6482C19.7852 20.771 20.1032 20.8342 20.4243 20.8342C20.7454 20.8342 21.0633 20.771 21.3595 20.6482C21.6558 20.5255 21.9244 20.3456 22.1499 20.1191C22.3753 19.8926 22.5531 19.624 22.6728 19.3289C22.7925 19.0338 22.8519 18.718 22.8473 18.4C22.8473 15.1497 25.8527 12.8 28.5011 12.8C31.1495 12.8 34.1549 15.1497 34.1549 18.4C34.1549 19.7947 33.784 20.6488 33.1074 21.5656C32.4308 22.4824 31.3764 23.3937 30.1575 24.425C27.7196 26.4875 24.4627 29.44 24.4627 34.4C24.4581 34.718 24.5175 35.0338 24.6372 35.3289C24.7569 35.624 24.9347 35.8926 25.1601 36.1191C25.3856 36.3456 25.6542 36.5255 25.9505 36.6482C26.2467 36.771 26.5646 36.8342 26.8857 36.8342C27.2068 36.8342 27.5248 36.771 27.821 36.6482C28.1172 36.5255 28.3859 36.3456 28.6113 36.1191C28.8368 35.8926 29.0145 35.624 29.1343 35.3289C29.254 35.0338 29.3133 34.718 29.3088 34.4C29.3088 31.36 30.8979 30.1125 33.3062 28.075C34.5103 27.0563 35.8789 25.9426 37.0196 24.3969C38.1603 22.8512 39.001 20.8053 39.001 18.4C39.001 12.3735 33.881 8 28.5011 8ZM26.8857 40C25.8147 40 24.7875 40.4214 24.0301 41.1716C23.2728 41.9217 22.8473 42.9391 22.8473 44C22.8473 45.0609 23.2728 46.0783 24.0301 46.8284C24.7875 47.5786 25.8147 48 26.8857 48C27.9568 48 28.984 47.5786 29.7413 46.8284C30.4987 46.0783 30.9241 45.0609 30.9241 44C30.9241 42.9391 30.4987 41.9217 29.7413 41.1716C28.984 40.4214 27.9568 40 26.8857 40Z" fill="white"/>
                        </svg>
                        </span>
                    </label>
                    <div className="homeQaItemActive my-6 pr-4 text-xl">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default HomeQa;
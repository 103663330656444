import { BiChevronLeft } from "react-icons/bi";
import NavbarMenuItemChild from "./NavbarMenuItemChild";

const NavbarMenuItem = ({item}) => {

    const megaMenuToggler = () => {
        document.querySelector(`.megaMenu-${item.key}`).classList.toggle('show')
        document.querySelector(`.chevronLeft-${item.key}`).classList.toggle('chevronActive')
    }

    return ( 
        <div className="navbarItem">
            <span onClick={megaMenuToggler} className={`navbarItemTitle navbarItemTitle-${item.key} flex flex-row items-center font-bold`}>
                <button className="py-2 pr-4 transition-all duration-300">
                    {item.title}
                </button>
                {item.items && (
                    <span className={`chevronLeft chevronLeft-${item.key} text-3xl transition-all duration-300`}>
                        <BiChevronLeft />
                    </span>
                )}
            </span>
            {item.items && (
                <div className={`megaMenu megaMenu-${item.key} w-full px-4 md:p-4 flex-col md:flex-row justify-between items-start shadow-xl rounded-br-nonerounded-bl-none md:rounded-br-2xl  md:rounded-bl-2xl `}>
                    <div className="megaMenuCol w-full md:w-1/3 md:mx-4 flex flex-col justify-start items-start">
                        {item.items.slice(0, item.items.length/2).map(e=>{
                            return <NavbarMenuItemChild key={e.key} item={e} />
                        })}
                    </div>
                    <div className="megaMenuCol w-full md:w-1/3 md:mx-4 flex flex-col justify-start items-start">
                        {item.items.slice(item.items.length/2, item.items.length).map(e=>{
                            return <NavbarMenuItemChild key={e.key} item={e} />
                        })}
                    </div>
                    <div className="megaMenuCol pcOnly w-full h-full  py-8 md:w-1/3 md:mx-4 flex justify-center items-center">
                        {item.key==="web-design" ? (
                            <div className="navbarWebIcon w-full h-full "></div>
                        ) : (
                            <div className="navbarGfxIcon w-full h-full "></div>
                        )}

                    </div>
                </div>
            )}
        </div>
     );
}
 
export default NavbarMenuItem;
import { useState } from "react";
import { Link } from "react-router-dom";
import HomePortfolioItem from "./PortfolioItem";

const HomePortfolio = ({products}) => {

    const webPortfolio = products[1].find(e=>e.key==='portfolio').childsWeb
    const gfxPortfolio = products[1].find(e=>e.key==='portfolio').childsGraphic

    const [activeItem, setActiveItem] = useState(webPortfolio)

    const webItemToggler = (e) => {
        setActiveItem(webPortfolio)
    }
    const gfxItemToggler = (e) => {
        setActiveItem(gfxPortfolio)
    }

    return ( 
        <section id="portfolio" className="homePortfolio w-full container mx-auto mt-16 md:mt-24 px-4 md:px-0 flex flex-col justify-start items-center text-primary">
            <h2 className="text-5xl font-bold">نمونه کار ها</h2>
            <p className="mt-8 text-xl leading-loose">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
            </p>
            <div className="homePortfolioButtons mt-8 p-1 text-2xl font-bold shadow-md border-2 border-opacity-10 rounded-lg border-primary">
                <button onClick={webItemToggler} className={`ml-2 px-8 py-2 rounded-md ${activeItem===webPortfolio ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                    وبسایت
                </button>
                <button onClick={gfxItemToggler} className={`mr-2 px-8 py-2 rounded-md ${activeItem===gfxPortfolio ? 'bg-primary text-white':'bg-white text-primary'} transition-all duration-300`}>
                    گرافیک
                </button>
            </div>
            <div className="homePortfolioContent w-full mt-12 grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-8">
                {activeItem.slice(0,6).map(e=>{
                    return <HomePortfolioItem key={e.key} item={e} />
                })}
            </div>
            <div className="homePortfolioLink mt-16">
                <Link to={activeItem===webPortfolio ? '/webportfolio':'graphicportfolio'} className={`px-12 py-3 text-2xl shadow-md border-2 rounded-lg border-primary hover:bg-primary hover:text-white transition-all`}>
                    سایر نمونه کارها
                </Link>
            </div>

        </section>
     );
}

export default HomePortfolio;
import { NavLink } from "react-router-dom";

const NavbarMenuItemChild = ({item}) => {
    return ( 
            <NavLink to={item.link ? item.link:'/'} className="navbarMenuItemChild w-full mt-1 md:my-2 py-2 hover:pr-4 flex flex-grow flex-row justify-start items-center border-b-2 border-primary border-opacity-5 transition-all duration-300">
                <div className="navItemIcon ml-4">
                    <svg className="w-12 md:w-20 h-12 md:h-20 " viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d={item.icon1} className="transition-all duration-300" />
                        {item.icon2? (
                        <path d={item.icon2} className="transition-all duration-300" />
                        ):
                        ""}
                        {item.icon3? (
                        <path d={item.icon3} className="transition-all duration-300" />
                        ):
                        ""}
                    </svg>
                </div>
                <div className="navItemContent">
                    <h2 className="text-xl md:text-2xl transition-all duration-300">
                        {item.title}
                    </h2>
                    <h4 className="mt-2 text-xs md:text-sm opacity-50 transition-all duration-300">
                        {item.desc}
                    </h4>
                </div>
        </NavLink>
     );
}
 
export default NavbarMenuItemChild;
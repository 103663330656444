import HomeServicesCarouselItem from "./ServicesCarouselItem";
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const HomeServicesCarousel = ({item}) => {

    SwiperCore.use([Autoplay]);

    return ( 
        <div className="homeServiceCarousel w-full flex flex-row justify-between items-start overflow-hidden">

        <Swiper
            className="swiperCarousel w-full mb-20 pt-20"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            loop='true'
            speed={3000}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            }}
            breakpoints={{
                576: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
                1024: {
                  // width: 768,
                  slidesPerView: 4,
                },
                2000: {
                    slidesPerView: 6,
                }
            }}
            
            // onSlideChange={() => console.log('slide change')}
        >
            {item.items.map(e=>{
                return (
                    <SwiperSlide key={e.key}>
                        <HomeServicesCarouselItem item={e} />
                    </SwiperSlide>
                )
            })}
        </Swiper>






        </div>
     );
}

export default HomeServicesCarousel;
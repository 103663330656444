const HomeClientCommentsItem = ({item}) => {
    return ( 
        <div className="mx-2 flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/3 ml-0 md:ml-12 flex flex-row justify-center md:justify-end">
                <img className="w-20 md:w-40 h-20 md:h-40 rounded-full" src={item.icon} alt={item.name} />
            </div>
            <div className="w-full md:w-2/3 mt-4 md:mt-0 flex flex-col justify-start items-center md:items-start">
                <h6 className="mb-2 text-2xl font-bold">
                    {item.name}
                </h6>
                {item.username && 
                <p className="">
                    {item.username}
                </p>
                }
                <p className="mt-2 text-xl leading-loose">
                    {item.comment}
                </p>
            </div>
        </div>
        
     );
}
 
export default HomeClientCommentsItem;
import { useState } from "react"
import HomePlansItem from "./PlansItem"

const HomePlans = ({plans}) => {

    const webPlans = plans[0].items
    const gfxPlans = plans[1].items

    const [activeItem, setActiveItem] = useState(gfxPlans[0])

    const firstItemToggler = (e) => {
        setActiveItem(webPlans[0])
    }
    const secondItemToggler = (e) => {
        setActiveItem(webPlans[1])
    }
    const thirdItemToggler = (e) => {
        setActiveItem(gfxPlans[0])
    }
    const fourthItemToggler = (e) => {
        setActiveItem(gfxPlans[1])
    }

    return ( 
        <section id="plans" className="homePlans w-full container mx-auto mt-32 md:mt-40 px-4 md:px-0 flex flex-col justify-start items-center text-primary">
            <div className="w-full flex flex-col md:flex-row justify-start items-center">
                <h2 className="text-5xl font-bold">
                    پلن ها
                </h2>
                <p className="mt-8 md:mt-0 mr-0 md:mr-8 text-xl">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                </p>
            </div>

            <div className="w-full mt-4 md:mt-16 flex flex-col justify-start items-center">
                <div className="homePlansButtons p-1 flex flex-col md:flex-row justify-start items-center text-lg md:text-2xl font-bold shadow-md border-2 border-opacity-10 rounded-lg border-primary">
                    <button onClick={thirdItemToggler} className={`${activeItem===gfxPlans[0] ? 'bg-primary text-white':'bg-white text-primary'} w-full md:w-auto mb-2 md:mb-0 md:ml-2 px-8 py-2 rounded-md transition-all duration-300`}>
                        {gfxPlans[0].title}
                    </button>
                    <button onClick={fourthItemToggler} className={`${activeItem===gfxPlans[1] ? 'bg-primary text-white':'bg-white text-primary'} w-full md:w-auto mb-2 md:mb-0 md:ml-2 px-8 py-2 rounded-md transition-all duration-300`}>
                        {gfxPlans[1].title}
                    </button>
                    <button onClick={firstItemToggler} className={`${activeItem===webPlans[0] ? 'bg-primary text-white':'bg-white text-primary'} w-full md:w-auto mb-2 md:mb-0 md:ml-2 px-8 py-2 rounded-md transition-all duration-300`}>
                        {webPlans[0].title}
                    </button>
                    <button onClick={secondItemToggler} className={`${activeItem===webPlans[1] ? 'bg-primary text-white':'bg-white text-primary'} w-full md:w-auto px-8 py-2 rounded-md transition-all duration-300`}>
                        {webPlans[1].title}
                    </button>
                </div>
                <div className="homePlansContent w-full mt-4 md:mt-16 flex flex-col md:flex-row justify-between items-center">
                    {activeItem.plans.slice(0,3).map(e=>{
                        return <HomePlansItem key={e.key} item={e} />
                    })}
                </div>

            </div>
        </section>
     );
}
 
export default HomePlans;
const HomeFaqItem = ({title, content, icon}) => {
    return ( 
        <div className="faqItem relative z-0 w-full ml-4 last:ml-0 py-12 px-4 flex flex-col justify-start items-center cursor-pointer rounded-xl bg-opacity-5 bg-primary hover:bg-secondary text-primary hover:text-white transition-all duration-300">
            <svg className="animItemHoverCircleBR -z-10 opacity-20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="white"/>
            </svg>
            <svg className="animItemHoverCircleBR2 z-10 opacity-20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="white"/>
            </svg>
            <h3 className="w-full z-0 text-center text-3xl font-bold">{title}</h3>  
            <img className="z-0 w-32 h-32 mt-8" src={icon} alt={title} />
            <p className="z-0 w-full mt-8 text-xl leading-loose">{content}</p>
        </div>
     );
}

export default HomeFaqItem;
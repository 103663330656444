import { Link } from "react-router-dom";
import HomePlansItemLine from "./PlansItemLine";

const HomePlansItem = ({item}) => {
    return ( 
        <Link to="/" className="homePlansItemLink w-full md:w-1/3 mx-2 my-2 md:my-0">
            <div className="homePlansItem relative z-0 w-full pt-12 pb-6 md:py-12 px-4 flex flex-col justify-start items-center rounded-xl overflow-hidden shadow-lg">
                <div className="homePlansItemHover -z-10 absolute w-full top-0 right-0 bottom-0 left-0 transition-all duration-500"></div>
                <h4 className="text-3xl font-bold">{item.title}</h4>
                <div className="w-full my-4 md:my-8">
                    {item.items.slice(0,7).map(e=>{
                        return <HomePlansItemLine key={e} item={e} />
                    })}
                </div>
                <h4 className="text-2xl md:text-3xl">
                    <span className="font-bold">{item.price}</span>
                    <span> تومان</span>    
                </h4>
            </div>
        </Link>
     );
}

export default HomePlansItem;
import SwiperCore, { Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import HomeHeaderCarouselItem from "./HeaderCarouselItem";


const HomeHeaderCarousel = () => {
    SwiperCore.use([Autoplay]);

    const sliderItems = [
        {
            key:1,
            title: 'طراحی سایت اختصاصی',
            desc: 'تا افتتاح سایت فروشگاه و یا شرکت خود، تنها یک قدم فاصله دارید',
            cta: 'اطلاعات بیشتر',
            link: '/cta',
            icon1: './img/hero-web.svg',
        },
        {
            key:2,
            title: 'طراحی لوگو برای برند شما',
            desc: 'ما در استودیو ریور به شما کمک میکنیم تا یک فروشگاه در دنیایی پر مخاطب، افتتاح کنید ...',
            cta: 'شروع کنید',
            link: '/cta',
            icon1: './img/home-hero.png',
        }
    ]

    return ( 
        <Swiper
            className="swiperCarousel w-full h-full"
            modules={[A11y]}
            spaceBetween={0}
            loop='true'
            speed={1000}
            slidesPerView='1'
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            }}
        >

            {sliderItems.map(e=>{
                return (
                    <SwiperSlide key={e.key}>
                            <HomeHeaderCarouselItem item={e} />
                    </SwiperSlide>
            )})}
        </Swiper>
     );
}
 
export default HomeHeaderCarousel;
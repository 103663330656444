import { Link } from "react-router-dom";

const HomeHeaderCarouselItem = ({item}) => {
    return ( 
        <div className="w-full h-full z-10 flex flex-col md:flex-row justify-between md:justify-center items-center md:items-end">
            <div className="h-1/2 md:h-full px-2 flex flex-col justify-center md:justify-center items-center text-primary">
                <h1 className="text-3xl lg:text-5xl font-bold">
                    {item.title}
                </h1>
                <p className="animate-pulse mt-4 md:mt-8 text-lg md:text-2xl text-center leading-loose">
                    {item.desc}
                </p>
                <Link to={item.link} className="mt-8 py-3 px-12 rounded-md bg-primary hover:bg-secondary text-white text-xl lg:text-2xl font-bold transition-all">{item.cta}</Link>
            </div>
            <div className="h-1/2 md:h-full flex flex-col justify-end">
                <img className="h-full object-cover object-bottom" src={item.icon1} alt={item.title} />
            </div>
        </div>
     );
}

export default HomeHeaderCarouselItem;